@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Noto+Serif:ital,wght@0,300;0,400;0,700;1,400;1,700&family=Poppins:wght@300;400;500;600;700;800&display=swap');

/************************************************************************/
/* General */
body {
  font-family: 'Noto Serif' !important;
  color: rgba(74, 74, 74, 1) !important;
  top: 0px !important;
}

.fbrito-space {
  height: 21px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.row,
[class^='col-'] {
  margin: 0rem !important;
  padding: 0rem !important;
}

.fbrito-block-padding,
.row.fbrito-block-padding {
  padding: 0rem 4rem !important;
}

.fbrito-block-padding-4-4 {
  padding: 4rem 4rem !important;
}

.fbrito-block-padding-2 {
  padding: 0rem 0.5rem !important;
}

.fbrito-has-error {
  border: 1px solid rgb(247, 164, 164) !important;
}

.fbrito-input-error {
  color: red !important;
}

.fbrito-bg-grey {
  background-color: rgba(241, 241, 241, 1);
}

.fbrito-bg-grey-light {
  background-color: rgba(247, 247, 247, 1);
}

.fbrito-page-title {
  text-align: center;
  width: 100%;
  padding: 57px 0rem;
  font-family: 'Poppins';
  font-size: 33px;
  font-weight: 700;
}

.fbrito-service-free-text h2, .ck h2 {
  text-align: center;
  width: 100%;
  padding: 15px 0rem;
  font-family: 'Poppins';
  font-size: 33px;
  font-weight: 700;
}

.fbrito-service-free-text h3, .ck h3 {
  padding: 15px 0rem;
  font-family: 'Poppins';
  font-size: 31px;
  font-weight: 700;
}

.fbrito-service-free-text h4, .ck h4 {
  padding: 15px 0rem;
  font-family: 'Poppins';
  font-size: 29px;
  font-weight: 700;
}

.fbrito-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

button {
  padding: 0.5rem !important;
  border-width: none !important;
  border-style: none !important;
  border-color: none !important;
  border-image: none !important;
}

.fbrito-btn-admin {
  background-color: rgb(77, 77, 77);
  border-radius: 4px;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  text-align: center;
  text-decoration: none;
  padding: 4px 8px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fbrito-btn-admin.btn-del, a.fbrito-btn-admin.btn-del {
  background-color: rgb(241, 95, 95) !important;
}

.fbrito-btn-admin.btn-del:hover, a.fbrito-btn-admin.btn-del:hover {
  color: white !important;
  background-color: rgb(255, 0, 0) !important;
  text-decoration: none !important;
}

.fbrito-btn-admin:hover {
  color: white;
  background-color: black;
  text-decoration: none;
}

.fbrito-btn {
  background-color: rgba(106, 213, 139, 1);
  border-radius: 4px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 200px;
  height: 45px;
}

.fbrito-btn:hover {
  color: white;
  background-color: #30D061;
  text-decoration: none;
}

.fbrito-btn.fbrito-btn--no-width {
  min-width: unset;
}

.fbrito-btn.fbrito-btn--red {
  background-color: rgba(255, 58, 58, 1) !important;
}

.fbrito-btn.fbrito-btn--red:hover {
  background-color: rgba(255, 58, 58, 0.7) !important;
}

.subscribe-input {
  font-size: 14px;
  height: 30px !important;
  font-family: 'Poppins';
  width: 210px;
}

.subscribe-btn {
  font-size: 11px;
  background-color: white;
  color: rgba(71, 81, 89, 1);
  margin-top: 2px;
  font-weight: 600;
  min-height: 12px;
  padding: 0.3rem !important;
  font-family: 'Poppins';
  display: flex;
  justify-content: center;
  width: 210px;
  cursor: default;
}

.subscribe-btn.disabled,
.subscribe-btn.disabled:hover {
  cursor: default;
  background-color: rgba(255, 255, 255, 0.774) !important;
  color: rgba(71, 81, 89, 0.774) !important;
}

.subscribe-btn:hover {
  cursor: pointer;
  background-color: #30D061;
  color: white;
}

.fbrito-cat-yoga {
  color: rgba(183, 123, 25, 1);
}

.fbrito-cat-design {
  color: rgba(42, 100, 209, 1);
}

.fbrito-cat-activities {
  color: rgba(28, 174, 219, 1) ;
}

.fbrito-cat-blog {
  color: rgb(48, 208, 97, 1);
}

.fbrito-bg-cat-yoga {
  background-color: rgba(183, 123, 25, 1) !important;
}

.fbrito-bg-cat-design {
  background-color: rgba(42, 100, 209, 1) !important;
}

.fbrito-bg-cat-activities {
  background-color: rgba(28, 174, 219, 1) !important;
}

.fbrito-service-labels {
  padding-bottom: 20px !important;
}

.fbrito-service-label {
  padding: 0.4rem;
  border-radius: 6px;
  margin-right: 10px;
  margin-top: 50px;
  font-size: 12px;
  background-color: rgb(48, 208, 97, 0.4);
}

.fbrito-service-label:hover {
  cursor: pointer;
  color: white;
  background-color: rgb(48, 208, 97, 1) !important;
}

.fbrito-bg-cat-blog {
  background-color: rgb(48, 208, 97, 1) !important;
}

.modal.fade.fbrito-modal, .modal-dialog {
  width: 100% !important;
  margin: 38px 0px 0px 0px !important;
}

.modal-content-img {
  width: 100vw !important;
  margin: 0px !important;
  background-color: #212124;
  height: calc(100vh - 74px);
  display: flex;
  justify-content: center;
}

.img-display {
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  padding-top: 2rem;
}

.img-display img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.fbrito-loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 25px;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 99999999999;
}

.fbrito-loader div {
  padding: 1rem;
}

/* End - General */


/* Menu */
.fbrito-language {
  text-transform: none !important;
  font-weight: bold;
  cursor: pointer;
}

.fbrito-menu {
  position: sticky;
  top: 0;
  color: rgba(71, 81, 89, 1);
  text-transform: uppercase;
  display: block;
}

.fbrito-menu.home.scrolled {
  background-color: white !important;
  color: rgba(71, 81, 89, 1) !important;
}

.fbrito-menu,
.fbrito-menu nav.navbar.navbar-expand-lg {
  background-color: white;
  z-index: 999999999999999999;
}

.fbrito-menu nav.navbar.navbar-expand-lg,
.fbrito-menu.home.scrolled nav.navbar.navbar-expand-lg {
  height: 74px;
  border-bottom: 1px solid rgba(241, 241, 241, 1);
}

.fbrito-menu.home nav.navbar.navbar-expand-lg {
  border-bottom: unset;
}

.fbrito-menu.home,
.fbrito-menu nav.navbar.navbar-expand-lg {
  background-color: transparent;
  z-index: 999999999999999999;
}

a.navbar-brand:hover {
  text-decoration: none !important;
}

.fbrito-menu .fbrito-name,
.fbrito-menu.home.scrolled .fbrito-name {
  height: 44px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 18px;
  color: rgba(71, 81, 89, 1) !important;
  letter-spacing: 0.07em;
  padding-left: 0.5rem;
}

.fbrito-menu.home .fbrito-name {
  color: white !important;
  font-size: 36px;
}

.fbrito-menu .fbrito-open-menu,
.fbrito-menu.home.scrolled .fbrito-open-menu {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  color: rgba(71, 81, 89, 1);
  text-transform: uppercase;
}

.fbrito-menu.home .fbrito-open-menu {
  color: white;
}

.fbrito-menu a,
.fbrito-menu.home.scrolled a {
  text-decoration: none;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  color: rgba(71, 81, 89, 1);
}

.fbrito-menu.home a {
  color: white;
}

.fbrito-menu a:hover,
.fbrito-menu.home.scrolled a:hover {
  color: rgba(71, 81, 89, 1);
  text-decoration: underline;
  text-underline-offset: 6px;
}

.fbrito-menu.home a:hover {
  color: white;
  text-decoration: underline;
  text-underline-offset: 6px;
}

.fbrito-menu ul li {
  padding: 0.5rem 1rem;
}

.fbrito-menu img.fbrito-logo,
.fbrito-menu.home.scrolled img.fbrito-logo {
  height: 33px;
  margin-top: -5px;
}

.fbrito-menu.home img.fbrito-logo {
  height: 40px;
  margin-top: -13px;
}

.fbrito-menu .navbar-collapse {
  display: flex !important;
  justify-content: flex-end !important;
}

.fbrito-menu-mob,
#myMenu {
  display: none;
}

.slider-btns {
  padding-bottom: 1rem;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 9998;
  height: calc(100vh - 74px);
}

.modal-close  {
 position: absolute;
 width: calc(100% - 2rem);
 display: flex;
 justify-content: end;
 z-index: 9999;
}

.modal-close button {
  background-color: unset;
}

.modal-close span {
  color: grey;
  font-size: 50px;
}

.modal-close span:hover  {
  color: #FFFFFF;
  cursor: pointer;
}

.slider-btn-previous,
.slider-btn-next {
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 50px;
}

.slider-btn-previous i:hover,
.slider-btn-next i:hover {
  color: #FFFFFF;
  cursor: pointer;
}

.slider-btn-previous {
  justify-content: flex-start;
}

.slider-btn-next {
  justify-content: flex-end;
}

/* End - Menu */


/* Footer */
.fbrito--footer {
  color: white;
  background-color: #475159;
  width: 100%;
  min-height: 347px;
  padding: 2rem !important;
  display: flex;
  flex-direction: column;  
}

.fbrito--footer-01 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.fbrito--footer-01 .fbrito--footer-01-1 .fbrito-image img {
  margin-top: -5px;
}

.fbrito--footer-01 .fbrito--footer-01-1 {
  width: 250px;
}

.fbrito--footer-01 .fbrito--footer-01-1 .fbrito-name {
  padding-left: 9.28px;
  position: absolute;
  font-family: 'Montserrat';
  font-size: 19px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.fbrito--footer-02 {
  height: 160px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.fbrito--footer-02-1 div {
  padding-bottom: 15px;
}

.fbrito--footer-02-1 div a {
  text-decoration: none;
  color: #FFFFFF;
  cursor: pointer;
}

.fbrito--footer-02-1, .fbrito--footer-02-4 , .fbrito--footer-02-3 {
  padding: 3rem;
  font-family: 'Poppins';
  font-size: 20px;
}

.fbrito--footer-02-2 {
  display: flex;
  flex-direction: row;
  width: 204px;
  justify-content: space-evenly;
  padding-top: 45px;
}

.fbrito--footer-02-2 a img, .fbrito--footer-02-2 a {
  width: 44px;
  height: 44px;
}

.fbrito--footer-02-3 {
  margin-top: -40px;
}

.fbrito--footer-02-3-1 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.fbrito--footer-02-3-2 {
  position: relative;
  padding: 14px 0px;
}

.fbrito--footer-02-3-2 input {
  width: 383px;
  height: 51px;
  border-radius: 6px;
  border: 1px solid #C4C4C4;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-left: 2rem;
  padding-right: 135px;
}

.fbrito--footer-02-3-2 input::placeholder {
  color: #FFFFFF;
  font-size: 16px;
}

.fbrito--footer-02-3-2 button {
  position: absolute;
  margin-left: -134px;
  margin-top: 2px;
  width: 132px;
  background-color: rgba(106, 213, 139, 0.8);;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  border-radius: 5px;
  height: 47px;
}

.fbrito--footer-02-3-2 button:hover {
  background-color: rgba(106, 213, 139, 1);
}

.fbrito--footer-02-3-2 button.disabled,
.fbrito--footer-02-3-2 button.disabled:hover {
  cursor: default !important;
  background-color: rgba(255, 255, 255, 0.774) !important;
  color: rgba(71, 81, 89, 0.774) !important;
}

.fbrito--footer-02-3-3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  cursor: pointer;
}

.fbrito--footer-03 {
  font-family: 'Poppins';
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 90px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}
/* End - Footer */


/* Home Page */
.fbrito-home-page {
  margin-top: -80px;
}

.fbrito-home-page .fbrito-intro {
  position: relative;
}

.fbrito-home-page .fbrito-intro .fbrito-intro-01 {
  background: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("./assets/imgs/fbrito-homepage-header-resized.jpg");
  width: 100%;
  height: 800px;
}

.fbrito-home-page .fbrito-intro .fbrito-intro-02 {
  display: block !important;
  font-weight: 400;
  position: absolute;
  bottom: 120px;
  width: 100%;
  text-align: center;
  padding: 0rem 2rem;
  color: white;
  font-size: 33px;
  font-style: italic;
  font-family: 'Noto Serif';
}

.fbrito-home-page .fbrito-intro .fbrito-intro-03,
.fbrito-home-page .fbrito-intro .fbrito-intro-04 {
  display: none !important;
}

.fbrito-page-subtitle {
  display: block !important;
  text-align: center;
  width: 100%;
  padding: 2rem 0rem;
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 700;
}

.fbrito-page-subtitle-01 {
  display: none !important;
}

.row.fbrito-categories-block {
  padding: 3rem 0rem !important;
}

.row.fbrito-categories-block img {
  height: 140px;
}

.fbrito-category-design {
  border-left: 2px solid rgba(241, 241, 241, 1);
  border-right: 2px solid rgba(241, 241, 241, 1);
}

.row.fbrito-categories-block .fbrito-category-descrption-00 {
  padding: 0rem 2rem 2rem 2rem;
  font-size: 16px;
  font-family: 'Noto Serif';
  font-weight: 400;
  line-height: 24px;
}

.fbrito-service-card {
  position: relative;
  background-color: white;
  height: 387px;
  width: 316px;
  margin-bottom: 48px;
  color: rgba(74, 74, 74, 1) !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.10);
  border-radius: 2px;
}

.row.fbrito-row-responsive {
  width: 100%;
}

.fbrito-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.fbrito-service-card.fbrito-cat-bdr-yoga:hover {
  text-decoration: none;
  border: 1px solid rgba(183, 123, 25, 1);
  width: calc(316px + 2px) !important;
  height: calc(387px + 2px) !important;
  margin-top: -1px !important;
}

.fbrito-service-card.fbrito-cat-bdr-design:hover {
  text-decoration: none;
  border: 1px solid rgba(42, 100, 209, 1);
  width: calc(316px + 2px) !important;
  height: calc(387px + 2px) !important;
  margin-top: -1px !important;
}

.fbrito-service-card.fbrito-cat-bdr-activities:hover {
  text-decoration: none;
  border: 1px solid rgba(28, 174, 219, 1);
  width: calc(316px + 2px) !important;
  height: calc(387px + 2px) !important;
  margin-top: -1px !important;
}

.fbrito-service-card.fbrito-cat-bdr-blog:hover {
  text-decoration: none;
  border: 1px solid rgba(48, 208, 97, 1);
  width: calc(316px + 2px) !important;
  height: calc(387px + 2px) !important;
  margin-top: -1px !important;
}

.fbrito-service-card .fbrito-service-card__img {
  width: 100%;
  height: 185px;
  background: no-repeat;
  background-size: cover;
  background-position: center;
}

.fbrito-service-card .fbrito-service-card__name {
  font-family: 'Poppins';
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Poppins';
  padding: 1rem;
}

.fbrito-service-card .fbrito-service-card__comment {
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 14px;
  padding: 0rem 1rem 0.5rem 1rem;
  color: rgba(100, 100, 100, 1);
}

.fbrito-service-card .fbrito-service-card__description {
  padding: 0rem 1rem 1rem 1rem;
  font-family: 'Noto Serif';
  font-size: 16px;
  color: rgba(74, 74, 74, 1);
  line-height: 24px;
}

.fbrito-home-page .carousel-item {
  height: 200px;
  width: 100%;
  text-align: center;
}

.fbrito-home-page .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100% !important;
}

.fbrito-home-quotes {
  padding: 90px 2rem 60px 2rem;
}

.fbrito-quotes .fbrito-quote-01 {
  text-align: center;
  min-height: 50px;
  font-size: 33px;
  font-style: italic;
  color: rgba(125, 125, 125, 1);
  line-height: 50px;
}

.fbrito-quotes .fbrito-quote-02 {
  padding-top: 48px;
  min-height: 35px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 23px;
  color: rgba(125, 125, 125, 1);
  text-align: center;
}

.carousel-indicators li {
  height: 9px !important;
  width: 9px !important;
  background-color: rgba(98, 106, 113, 0.5) !important;
  border-radius: 100%;
}

.carousel-indicators li.active {
  height: 9px !important;
  width: 9px !important;
  border-radius: 100%;
  background-color: rgba(98, 106, 113, 1) !important;
}

.carousel-inner {
  padding-bottom: 80px !important;
}

/* End - Home Page */


/* About Page */
.fbrito-about .fbrito-about-00 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 270px;
}

.fbrito-about .fbrito-about-00 .fbrito-about-00-1 {
  width: 1024px;
  height: 233px;
  padding: 0rem 2rem;
  display: flex;
  flex-direction: row;
}

.fbrito-about .fbrito-about-00 .fbrito-about-00-1 .fbrito-about-01 {
  width: 313px;
  /* 233 + 80 */
}

.fbrito-about .fbrito-about-00 .fbrito-about-00-1 .fbrito-about-01 img {
  width: 233px;
  border-radius: 100%;
}

.fbrito-about .fbrito-about-00 .fbrito-about-00-1 .fbrito-about-02 {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgba(71, 81, 89, 1);
  line-height: 30px;
  font-family: 'Noto Serif';
  font-weight: 500;
  height: 233px;
  width: calc(1024px - 313px);
  /* 1024 - (233 + 80) = 711*/
}

.fbrito-about .fbrito-about-00 .fbrito-about-00-1 .fbrito-about-02.fbrito-about-02--text-c {
  font-size: 26px !important;
  line-height: 34px;
}

.fbrito-about .fbrito-about-00 .fbrito-about-00-1 .fbrito-about-02.fbrito-about-02--text-c .fbrito-about-02-1  .fbrito-about-02-1-1 {
  padding-bottom: 2rem;
}

.fbrito-about .fbrito-about-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.fbrito-about .fbrito-about-imgs {
  width: 1024px;
  padding: 96px 1rem 64px 1rem;
}

.fbrito-about .fbrito-about-text-01 {
  font-weight: 500;
  width: 671px;
  font-family: 'Noto Serif';
  font-size: 20px;
  color: rgba(71, 81, 89, 1);
  line-height: 30px;
  padding: 32px 1rem;
}

.fbrito-about .fbrito-about-text-02 {
  width: 1024px;
  font-family: 'Noto Serif';
  font-size: 33px;
  font-style: italic;
  color: rgba(125, 125, 125, 1);
  line-height: 50px;
  font-weight: 500;
  padding: 32px 1rem;
}

/* End - About Page */


/* Schedule Page */
.fbrito-schedule-card {
  position: relative;
  background-color: white;
  height: 167px;
  width: 316px;
  text-align: center;
  font-family: 'Poppins';
  font-size: 16px;
  padding: 36px;
  margin-bottom: 48px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.10);
  border-radius: 2px;
}

.fbrito-schedule-card .fbrito-schedule-card__title {
  text-transform: uppercase;
  font-weight: 700;
}

.fbrito-schedule-card .fbrito-schedule-card__daterange {
  font-weight: 700;
  padding: 13px 0px;
}

.fbrito-schedule-card .fbrito-schedule-card__edit-left-top {
  position: absolute;
  top: 0;
  left: 0;
}

.fbrito-schedule-card .fbrito-schedule-card__edit-right-top {
  position: absolute;
  top: 0;
  right: 0;
}

/* End - Schedule Page */


/* Yoga Page */
/* End - Yoga Page */


/* Quotes and images section */
.fbrito-quotes-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.fbrito-quotes-slider {
  width: 1024px;
  padding: 0rem 1rem;
}

.fbrito-quotes-imgs {
  width: 1024px;
  padding: 96px 1rem 64px 1rem;
}

/* End - Quotes and images section */


/* Activities Page */
/* End - Activities Page */


/* Contacts Page */
/* End - Contacts Page */


/* Service Page */
.fbrito-service-page .fbrito-service-title {
  text-align: center;
  width: 100%;
  padding: 52px 22px;
  font-family: 'Poppins';
  font-size: 33px;
  font-weight: 700;
  text-transform: uppercase;
}

.fbrito-service-page .fbrito-service-description-00 {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.fbrito-service-page .fbrito-service-description-01 {
  width: 671px;
  font-size: 20px;
  color: rgba(53, 51, 48, 1);
  line-height: 30px;
  font-family: 'Noto Serif';
  font-weight: 500;
  padding-bottom: 55px;
}

.fbrito-service-page .fbrito-service-img {
  width: 671px;
  padding: 0px 0px 40px 0px;
}

.fbrito-service-page .fbrito-service-free-text {
  width: 671px;
  padding-bottom: 50px;
  color: rgba(53, 51, 48, 1);
}

.fbrito-service-free-text-p {
  padding: 50px 5% !important;
}

.fbrito-share-text {
  height: 29px;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 600;
  color: rgba(66, 66, 66, 1);
  line-height: 29px;
  margin: 14px 0px;
  padding: 0px 0px 14px 0px;
}

.at-icon-wrapper {
  margin: 3px;
}

a.at-icon-wrapper.at-share-btn {
  height: 36px !important;
  width: 36px !important;
  border-radius: 100% !important;
}

/* End - Service Page */


/* Not Found Page */
.fbrito-notfound-page .fbrito-bg-grey {
  padding-top: 40px;
}

.fbrito-notfound-page .row .fbrito-notfound-page-description {
  margin-top: -3rem !important;
  padding: 1rem;
  font-family: 'Noto Serif';
  font-weight: 500;
  font-size: 24px;
  color: rgba(71, 81, 89, 1);
  line-height: 43px;
}

/* End - Not Found Page */
/************************************************************************/


/************************************************************************/
/* Admin Home Page */

.fbrito-admin-home .fbrito-description {
  font-family: 'Noto Serif';
  font-weight: 500;
  font-size: 16px;
  color: rgba(125, 125, 125, 1);
  line-height: 24px;
}

.fbrito-admin-home input {
  min-width: 316px;
  height: 45px;
  border: none !important;
}

.fbrito-admin-home input::placeholder {
  height: 24px;
  width: 100%;
  font-family: 'Noto Serif';
  font-size: 16px;
  color: rgba(125, 125, 125, 1);
  text-align: center;
  line-height: 24px;
}

/* End - Admin Home Page */

/* Admin Schedule Page */
.fbrito-admin-schedule .row.fbrito-add-block,
.fbrito-admin-quotes .row.fbrito-add-block {
  padding: 0rem 1rem 2rem 1rem !important;
}

/* End - Admin Schedule Page */

/* Admin Table */
.fbrito-table .row {
  padding: 0.5rem !important;
  background-color: white;

}

.fbrito-table .fbrito-table-title {
  font-size: 16px;
  text-align: center;
  width: 100%;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid rgba(74, 74, 74, 0.5);
  padding-bottom: 0.5rem !important;
}

.fbrito-table [class^='col-'].fbrito-table-col {
  border-bottom: 1px solid rgba(74, 74, 74, 0.2) !important;
  padding: 0rem 0.5rem !important;
}

.fbrito-table [class^='col-'].fbrito-table-col-left {
  border-bottom: 1px solid rgba(74, 74, 74, 0.2) !important;
  padding-right: 0.5rem !important;
}

.fbrito-table [class^='col-'].fbrito-table-col-right {
  border-bottom: 1px solid rgba(74, 74, 74, 0.2) !important;
}
/* End - Admin Table */


/* Admin  - Gallery Page */
img.fbrito-admin-gallery {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border: 1px solid black !important;
  margin: 5px 0px 20px 0px;
}

.fbrito-img-container {
  position: relative !important;
}

.fbrito-order {
  position: absolute;
  top: 34px;
  left: 10px;
  font-size: 11px;
  font-weight: 600;
  font-family: 'Poppins';
  background-color: white;
  padding: 5px;
}
/* End - Admin  - Gallery Page */
/************************************************************************/



/************************************************************************/
/* For screens higher than 1300px version */
@media only screen and (min-width: 1300px) {
  .row.fbrito-row-responsive {
    width: 1200px;
  }
}

/* End - For screens higher than 1300px versio */
/************************************************************************/


/************************************************************************/
/* 1246px screen version */
@media only screen and (max-width: 1246px) {
  .fbrito-page-subtitle {
    display: none !important;
  }

  .fbrito-page-subtitle-01 {
    display: block !important;
    text-align: center;
    width: 100%;
    padding: 1rem 0rem;
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 700;
  }

  .fbrito-service-free-text-p {
    padding: 50px 5% !important;
  }
}

/* End - 1246px screen version */
/************************************************************************/


/************************************************************************/
/* 1076px screen version */
@media only screen and (max-width: 1076px) {
  .fbrito-home-page .fbrito-intro .fbrito-intro-03 {
    display: block !important;
    font-weight: 300;
    position: absolute;
    bottom: 70px;
    width: 100%;
    text-align: center;
    padding: 0rem 1rem;
    color: white;
    font-size: 26px;
    font-style: italic;
    font-family: 'Noto Serif';
  }

  .fbrito-home-page .fbrito-intro .fbrito-intro-02,
  .fbrito-home-page .fbrito-intro .fbrito-intro-04 {
    display: none !important;
  }
}

/* End - 1076px screen version */
/************************************************************************/


/************************************************************************/
/* 1040px screen version */
@media only screen and (max-width: 1040px) {

  .fbrito-menu.home,
  .fbrito-menu nav.navbar.navbar-expand-lg {
    background-color: white;
  }

  .fbrito-menu.home .fbrito-name,
  .fbrito-menu.home .fbrito-open-menu,
  .fbrito-menu.home a {
    color: rgba(71, 81, 89, 1) !important;
  }

  .fbrito-menu .fbrito-name,
  .fbrito-menu.home .fbrito-name {
    color: rgba(71, 81, 89, 1) !important;
    font-size: 18px;
  }

  .fbrito-menu .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    justify-content: start !important;
  }

  .fbrito-menu .collapse:not(.show) {
    display: none !important;
  }

  .fbrito-menu img.fbrito-logo,
  .fbrito-menu.home img.fbrito-logo {
    height: 33px;
    margin-top: -3px;
  }

  .fbrito-home-page {
    margin-top: 0px;
  }

  .fbrito-page-subtitle,
  .fbrito-page-subtitle-01 {
    padding: 1rem 0rem;
    font-size: 25px;
  }

  .fbrito-schedule-card {
    height: unset !important;
  }

  .fbrito-service-page .fbrito-service-description-01 {
    padding: 0px 8% 50px 8%;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  }

  .fbrito-service-page .fbrito-service-free-text {
    width: 100%;
    padding: 0px 8% 50px 8%;
  }

  .fbrito-service-free-text-p {
    padding: 50px 8% !important;
  }

  .fbrito-block-padding,
  .row.fbrito-block-padding {
    padding: 0rem 2rem !important;
  }

  .fbrito-about .fbrito-about-00 {
    height: unset;
  }

  .fbrito-about .fbrito-about-00 .fbrito-about-00-1 {
    width: 100%;
    height: unset;
    display: flex;
    flex-direction: column;
    padding: 0rem 8%;
  }

  .fbrito-about .fbrito-about-00 .fbrito-about-00-1 .fbrito-about-01 {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    width: 100%;
  }

  .fbrito-about .fbrito-about-00 .fbrito-about-00-1 .fbrito-about-02 {
    font-size: 16px;
    line-height: 24px;
    height: unset;
    width: 100%;
  }

  .fbrito-about .fbrito-about-00 .fbrito-about-00-1 .fbrito-about-02.fbrito-about-02--text-c {
    font-size: 20px !important;
    line-height: 30px !important;
    display: flex;
    justify-content: center;
  }

  .fbrito-about .fbrito-about-00 .fbrito-about-00-1 .fbrito-about-02.fbrito-about-02--text-c .fbrito-about-02-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .fbrito-about .fbrito-about-imgs,
  .fbrito-quotes-imgs {
    width: 100%;
    padding: 96px 8% 64px 8%;
  }

  .fbrito-quotes-slider {
    width: 100%;
  }

  .fbrito-about .fbrito-about-text-01 {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 32px 8%;
  }

  .fbrito-about .fbrito-about-text-02 {
    width: 100%;
    font-size: 21px;
    line-height: 32px;
    padding: 32px 8%;
  }

  .editor-service-quote {
    font-size: 23px !important;
    line-height: 30px !important;
    width: 100% !important;
    padding: 0rem 1rem !important;
    margin-left: 0px !important;
  }

  .fbrito-service-free-text-p {
    padding: 50px 5% !important;
  }
}

/* End - 1040px screen version */
/************************************************************************/


/************************************************************************/
/* 1200px-988px screen version */
@media only screen and (min-width: 988px) and (max-width: 1200px) {
  .fbrito-schedule-card {
    width: 280px !important;
  }

  .fbrito-service-card {
    width: 280px !important;
  }

  .fbrito-service-card.fbrito-cat-bdr-yoga:hover,
  .fbrito-service-card.fbrito-cat-bdr-design:hover,
  .fbrito-service-card.fbrito-cat-bdr-activities:hover,
  .fbrito-service-card.fbrito-cat-bdr-blog:hover {
    width: calc(280px + 2px) !important;
  }

  .fbrito-service-free-text-p {
    padding: 50px 5% !important;
  }

}

/* End - 1200px-988px screen version */
/************************************************************************/


/************************************************************************/
/* 988px-768px screen version */
@media only screen and (min-width: 768px) and (max-width: 988px) {
  .row.fbrito-row-responsive {
    width: 750px !important;
  }
  .fbrito-service-free-text-p {
    padding: 50px 5% !important;
  }
}

/* End - 988px-768px screen version */
/************************************************************************/


/************************************************************************/
/* 768px screen version */
@media only screen and (max-width: 768px) {
  .ck h2, .fbrito-page-title, .fbrito-service-free-text h2,  {
    font-size: 23px;
  }
  .fbrito-service-free-text h3, .ck h3 {
    font-size: 21px;
  }
  
  .fbrito-service-free-text h4, .ck h4 {
    font-size: 19px;
  }

  .fbrito-menu img.fbrito-logo,
  .fbrito-menu.home img.fbrito-logo {
    height: 30px;
    margin-top: -3px;
  }

  .fbrito-home-page .fbrito-intro .fbrito-intro-01 {
    background: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("./assets/imgs/fbrito-homepage-header-resized.jpg");
    width: 100%;
    height: 640px;
  }

  .fbrito-home-page .fbrito-intro .fbrito-intro-04 {
    display: block !important;
    font-weight: 300;
    position: absolute;
    bottom: 50px;
    width: 100%;
    text-align: center;
    padding: 0rem 1rem;
    color: white;
    font-size: 21px;
    font-style: italic;
    font-family: 'Noto Serif';
  }

  .fbrito-home-page .fbrito-intro .fbrito-intro-02,
  .fbrito-home-page .fbrito-intro .fbrito-intro-03 {
    display: none !important;
  }

  .fbrito-page-subtitle {
    display: block !important;
    font-size: 20px;
  }

  .fbrito-page-subtitle-01 {
    display: none !important;
  }

  .row.fbrito-categories-block img {
    height: 110px;
  }

  .fbrito-home-page .row.fbrito-categories-block,
  .fbrito-notfound-page .row.fbrito-categories-block {
    padding: 0rem 1rem !important;
  }

  .fbrito-home-page .row.fbrito-categories-block .col-sm-12.col-md-4,
  .fbrito-notfound-page .row.fbrito-categories-block .col-sm-12.col-md-4 {
    padding: 3rem 0rem !important;
  }

  .fbrito-home-page .fbrito-category-design,
  .fbrito-notfound-page .fbrito-category-design {
    border: none;
    border-bottom: 2px solid rgba(241, 241, 241, 1);
    border-top: 2px solid rgba(241, 241, 241, 1);
  }

  .fbrito-block-padding,
  .row.fbrito-block-padding {
    padding: 0rem 1rem !important;
  }

  .fbrito-home-quotes {
    padding: 50px 2rem;
  }

  .fbrito-quotes .fbrito-quote-01 {
    font-size: 21px;
    line-height: 32px;
  }

  .fbrito-quotes .fbrito-quote-02 {
    padding-top: 38px;
    font-size: 18px;
  }

  .fbrito-admin-home input {
    min-width: unset !important;
  }

  .fbrito-service-page .fbrito-service-title {
    font-size: 23px;
  }

  .fbrito-service-page .fbrito-service-img {
    width: 100%;
    padding: 0px 8% 40px 8%;
  }

  .fbrito-notfound-page .fbrito-bg-grey {
    padding-top: 0px;
  }

  .fbrito-notfound-page .row .fbrito-notfound-page-description {
    margin-top: -30px !important;
    padding: 1rem;
    font-size: 16px;
    color: rgba(53, 51, 48, 1);
    line-height: 24px;
  }

  .fbrito-notfound-page .fbrito-bg-grey .fbrito-notfound-page-space {
    display: none !important;
  }

  .fbrito-service-free-text-p {
    padding: 50px 5% !important;
  }

}

/* End - 768px screen version */
/************************************************************************/


/************************************************************************/
/* 1150px screen for menu mobile and footer version */
@media only screen and (max-width: 1150px) {

  /* Admin  - Gallery Page */
  img.fbrito-admin-gallery {
    width: 100%;
  }

  .fbrito-menu {
    display: none !important;
  }

  .fbrito-page {
    margin-top: 74px !important;
  }

  .fbrito-menu-mob,
  .fbrito-menu-mob.scrolled-mob,
  .fbrito-menu-mob.home.scrolled-mob {
    background-color: white;
    position: sticky;
    top: 0;
    display: block;
    z-index: 999999999999999999 !important;
    width: 100%;
    height: 74px;
    padding: 19px 22px;
    border-bottom: 1px solid rgba(241, 241, 241, 1);
    margin-top: -74px;
  }

  .fbrito-menu-mob.home {
    background-color: transparent;
    border-bottom: unset;
  }

  .fbrito-menu-mob-00 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  a.fbrito-menu-mob-01 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 60px);
    text-decoration: none;
  }

  a.fbrito-menu-mob-01:hover {
    text-decoration: none;
  }

  .fbrito-menu-mob.home .fbrito-menu-mob-01 .fbrito-name,
  .fbrito-menu-mob.home #menu-open {
    color: white;
  }

  .fbrito-menu-mob .fbrito-menu-mob-01 .fbrito-name,
  .fbrito-menu-mob.scrolled-mob .fbrito-menu-mob-01 .fbrito-name {
    height: 23px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    color: rgba(71, 81, 89, 1);
    letter-spacing: 0.07em;
    padding: 0rem 10px;
  }

  .fbrito-menu-mob #menu-open,
  .fbrito-menu-mob.scrolled-mob #menu-open,
  .fbrito-menu-mob #menu-close {
    cursor: pointer;
    height: 23px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    color: rgba(71, 81, 89, 1);
    width: 60px;
  }

  #myMenu.overlay {
    display: block;
    background-color: white;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 99999999999999999999999999999999999999999999;
  }

  .fbrito-menu-mob-options div.first a:first-child {
    border-top: unset !important;
  }

  .fbrito-menu-mob-options div a {
    height: 77px;
    border-top: 1px solid rgba(241, 241, 241, 1);
    /*rgba(225, 225, 225, 1);*/
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 68px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    color: rgba(71, 81, 89, 1);
    text-transform: uppercase;
    text-underline-offset: 6px;
  }

  /* Footer - Mobile version */
  .fbrito--footer {
    min-height: 500px;
  }

  .fbrito--footer-01 {
    display: flex;
    justify-content: center;
  }

  .fbrito--footer-02 {
    height: 300px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .fbrito--footer-02-1 div {
    padding-bottom: 2px;
    text-align: center;
    font-size: 16px;
  } 

  .fbrito--footer-02-1, .fbrito--footer-02-4 , .fbrito--footer-02-3 {
    padding: 2rem;
    font-size: 20px;
  }

  .fbrito--footer-02-2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 60px;
    margin-left: -5px;
  }

  .fbrito--footer-02-2 a img, .fbrito--footer-02-2 a {
    width: 32px;
    height: 32px;
    margin: 0px 10px;
  }

  .fbrito--footer-02-3-1 {
    text-align: center;
  }

  .fbrito--footer-02-3-2 input {
    width: 100%;
  }

  .fbrito--footer-02-3-3 {
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
  }

  .fbrito--footer-03 {
    font-size: 12px;
    width: 100%;
    padding: 0px 2rem;
    text-align: center;
  }

  .fbrito-service-free-text-p {
    padding: 50px 5% !important;
  }
  /* End - Footer - Mobile version */
}

/* End - 1150px screen for menu mobile and footer  version */
/************************************************************************/


/************************************************************************/
/* Handle image galery */
.fbrito-jg {
  display: flex;
  flex-wrap: wrap;
}

.fbrito-jg>a,
.fbrito-jg::after {
  --ratio: calc(var(--w) / var(--h));
  --row-height: 9rem;
  flex-basis: calc(var(--ratio) * var(--row-height));
}

.fbrito-jg>a {
  margin: 0.25rem;
  flex-grow: calc(var(--ratio) * 100);
  cursor: pointer;
}

.fbrito-jg::after {
  --w: 2;
  --h: 1;
  content: '';
  flex-grow: 1000000;
}

.fbrito-jg>a>img {
  display: block;
  width: 100%;
}

/* End - Handle image galery */
/************************************************************************/


/* Delete 000webhost logo */
img[src="https://cdn.000webhost.com/000webhost/logo/footer-powered-by-000webhost-white2.png"] {
  display: none !important;
}

/* End - Delete 000webhost logo */


/* Quill editor */
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  height: 30px !important;
  width: unset !important;
}

.ql-align-center {
  width: 100%;
  text-align: center;
}

.ql-align-right {
  width: 100%;
  text-align: right;
}

.ql-align-justify {
  width: 100%;
  text-align: justify;
}

.ql-editor-service-title:after {
  content: "Título";
  font-family: 'Poppins';
  font-weight: 700;
  color: rgba(71, 81, 89, 1);
  width: 100px;
}

.editor-service-title {
  text-align: center;
  width: 100%;
  padding: 50px 0rem;
  font-family: 'Poppins';
  font-size: 33px;
  font-weight: 700;
  color: rgba(71, 81, 89, 1);
}

.ql-editor-service-parag:after {
  content: "Descrição" !important;
  font-family: 'Noto Serif' !important;
  font-weight: 500 !important;
  color: rgba(71, 81, 89, 1) !important;
  width: 100px !important;
}

.ql-editor-service-quote:after {
  content: "Citação" !important;
  font-family: 'Noto Serif' !important;
  font-weight: 500 !important;
  width: 100px !important;
  font-style: italic;
  color: rgba(125, 125, 125, 1) !important;
}

.editor-service-parag {
  font-weight: 500 !important;
  font-family: 'Noto Serif' !important;
  font-size: 20px !important;
  color: rgba(53, 51, 48, 1) !important;
  line-height: 30px !important;
}

.editor-service-quote {
  text-align: center;
  font-size: 33px;
  font-style: italic;
  color: rgba(125, 125, 125, 1);
  line-height: 50px;
  width: calc(100% + 200px);
  font-family: 'Noto Serif';
  font-weight: 500;
  margin-left: -100px;
}

.fbrito-service-free-text img {
  object-fit: cover;
  width: 100%;
  min-height: 100%;
}

. iframe.ql-video {
  object-fit: cover;
  width: 100%;
  min-height: 300px;
}

/* Set dropdown font-families */
span[data-value="arial"]::before {
  font-family: "Arial" !important;
  content: "Arial" !important;
}

span[data-value="montserrat"]::before {
  font-family: "Montserrat" !important;
  content: "Montserrat" !important;
}

span[data-value="notoserif"]::before {
  font-family: "Noto Serif" !important;
  content: "Noto Serif" !important;
}

span[data-value="poppins"]::before {
  font-family: "Poppins" !important;
  content: "Poppins" !important;
}

/*
span[data-value="editor-service-title"]::before {
  content: "Título" !important;
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  color: rgba(71, 81, 89, 1) !important;
  width: 100px !important;
}

span[data-value="editor-service-parag"]::before {
  content: "Descrição" !important;
  font-family: 'Noto Serif' !important;
  font-weight: 500 !important;
  color: rgba(71, 81, 89, 1) !important;
  width: 100px !important;
} */
/* End - Set dropdown font-families */

/* Set content font-families */
.ql-font-arial {
  font-family: "Arial" !important;
}

.ql-font-montserrat {
  font-family: "Montserrat" !important;
}

.ql-font-notoserif {
  font-family: "Noto Serif" !important;
}

.ql-font-poppins {
  font-family: "Poppins" !important;
}

.disclaimer {
  display: none;
}

/* .ql-font-editor-service-title {
  text-align: center !important;
  width: 100% !important;
  padding: 57px 0rem !important;
  font-family: 'Poppins' !important;
  font-size: 33px !important;
  font-weight: 700 !important;
  color: rgba(71, 81, 89, 1) !important;
}  

.ql-font-editor-service-parag {
  font-weight: 500 !important;
  font-family: 'Noto Serif' !important;
  font-size: 20px !important;
  color: rgba(53, 51, 48, 1) !important;
  line-height: 30px !important;
}
*/
/* End - Set content font-families */

/* End - Quill editor */